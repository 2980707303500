<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="商品结构" width="1280px">
    <el-form class="head-container v s" label-position="right" label-width="78px">
      <el-form-item label="汇总级别:" class="filter-item">
        <el-radio-group v-model="query.goodsGroup" @change="redraw">
          <el-radio-button v-for="(v, k) in levels" :key="k" :label="k">{{v}}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排列方式:" class="filter-item">
        <el-radio-group v-model="query.saleValueGroup" @change="redraw">
          <el-radio-button v-for="(v, k) in fields" :key="k" :label="k">{{v.name}}</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div class="h sb bc-l">
      <div ref="bar" style="height: 300px; width:60%;"></div>
      <div ref="pie" style="height: 300px; width:40%;"></div>
    </div>
    <!-- 表格 -->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" border height="300px">
      <el-table-column prop="name" :label="levels[query.goodsGroup]" width="160" show-overflow-tooltip />
      <el-table-column v-for="(v, k) in fields" :key="v.id" :prop="k" :label="v.name" :formatter="v.formatter" width="280" v-if="v.show" show-overflow-tooltip />
    </el-table>
    <el-pagination :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" style="margin-top: 8px;" />
  </el-dialog>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      store: [],
      page: 1,
      size: 10,
      levels: {
        brandId: "品牌",
        seriesId: "系列",
        goodsCategoryId: "分类",
        spuId: "商品",
      },
      fields: {
        saleNumber: {
          name: "销售数量",
          ratio: 1,
          unit: "",
          show: true,
        },
        realSumMoney: {
          name: "销售金额",
          ratio: 0.000001,
          formatter: this.$price,
          unit: "（万元）",
          show: true,
        },
        sendNumber: {
          name: "出货数量",
          ratio: 1,
          unit: "",
          show: true,
        },
        sendMoney: {
          name: "出货金额",
          ratio: 0.000001,
          formatter: this.$price,
          unit: "（万元）",
          show: true,
        },
        // checkSaleNumber: {
        //   name: "已上报销售数量",
        //   ratio: 1,
        //   unit: "",
        //   show: true,
        // },
        // realCheckSumMoney: {
        //   name: "已上报销售金额",
        //   ratio: 0.000001,
        //   formatter: this.$price,
        //   unit: "（万元）",
        //   show: true,
        // },
        // checkSendNumber: {
        //   name: "已上报出货数量",
        //   ratio: 1,
        //   unit: "",
        //   show: true,
        // },
        // checkSendMoney: {
        //   name: "已上报出货金额",
        //   ratio: 0.000001,
        //   formatter: this.$price,
        //   unit: "（万元）",
        //   show: true,
        // },
      },
      query: {
        saleValueGroup: "saleNumber",
        goodsGroup: "brandId",
        page: 0,
        size: 10000,
      },
      chart: {
        bar: null,
        pie: null,
      },
      params: {},
      target: null,
    };
  },
  computed: {
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
  },
  methods: {
    resetQuery(params, target) {
      this.params = params;
      this.target = target;
      this.dialog = true;
      this.redraw();
    },
    redraw() {
      if (!this.target) return false;
      let params = Object.assign({}, this.query, this.params);
      if (params.dateGroup === "year") {
        params.begDate = new Date(this.target.year, 0, 1).getTime();
        params.endDate = new Date(this.target.year + 1, 0, 1).getTime();
      } else if (params.dateGroup === "month") {
        params.begDate = new Date(
          this.target.year,
          this.target.month - 1,
          1
        ).getTime();
        params.endDate = new Date(
          this.target.year,
          this.target.month,
          1
        ).getTime();
      } else if (params.dateGroup === "day") {
        params.begDate = new Date(
          this.target.year,
          this.target.month - 1,
          this.target.day
        ).getTime();
        params.endDate = new Date(
          this.target.year,
          this.target.month - 1,
          this.target.day + 1
        ).getTime();
      }

      this.loading = true;
      request({
        url: "@host:analysis;api/analy/purchase/sale/goods",
        method: "get",
        params: params,
      })
        .then((res) => {
          this.store = res.content;
          this.drawPieChart();
          this.drawBarChart();
        })
        .finally((_) => {
          this.loading = false;
        });
    },

    drawPieChart() {
      if (!this.chart.pie) {
        this.chart.pie = echarts.init(this.$refs.pie, "light");
      }
      let fn = this.query.saleValueGroup,
        fd = this.fields[fn],
        ds = this.store.map((o) => {
          return {
            name: o.name,
            value: Math.round(o[fn] * fd.ratio * 100) / 100,
          };
        });

      this.chart.pie.clear();
      this.chart.pie.setOption({
        grid: {
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        },
        title: {
          text: "汇总分析",
          left: "center",
          top: 10,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/>{a} : {c} ({d}%)",
        },
        series: [
          {
            name: this.levels[this.query.goodsGroup] + fd.name + fd.unit,
            type: "pie",
            radius: "70%",
            center: ["50%", "55%"],
            data: ds,
            label: {
              show: false,
            },
          },
        ],
      });
    },

    drawBarChart() {
      if (!this.chart.bar) {
        this.chart.bar = echarts.init(this.$refs.bar, "light");
      }

      let xds = [],
        fn = this.query.saleValueGroup,
        fd = this.fields[fn],
        ds = this.store.map((o) => {
          xds.push(o.name);
          return Math.round(o[fn] * fd.ratio * 100) / 100;
        });

      this.chart.bar.clear();
      this.chart.bar.setOption({
        grid: {
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        },
        title: {
          text: this.levels[this.query.goodsGroup] + fd.name + "前10",
          left: "center",
          top: 10,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 40,
          right: 10,
          bottom: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xds.slice(0, 10),
        },
        yAxis: {
          type: "value",
          name: fd.name + fd.unit,
        },
        series: [
          {
            name: fd.name + fd.unit,
            type: "bar",
            data: ds.slice(0, 10),
          },
        ],
      });
    },
  },
};
</script>

